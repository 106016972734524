import "./banner.css";
import img1 from "../../../assets/images/img1.png";
import img2 from "../../../assets/images/img2.png";
import img3 from "../../../assets/images/img3.png";
import img4 from "../../../assets/images/img4.png";
import img5 from "../../../assets/images/img5.png";
import img6 from "../../../assets/images/img6.png";
import {Button} from "../../../components/button/Button";
import virtua from "../../../assets/images/virtua.svg";
export const Banner = (props) => {
    return (
        <div className="banner-section flex items-center">
            <div className="col">
                <img className="swing" src={img1} alt="img1"/>
                <img className="swing-1" src={img2} alt="img2"/>
                <img className="swing-2-reverse" src={img3} alt="img3"/>
            </div>
            <div className="col">
                <h1>Get your <span>trap</span> <span>game</span> on.</h1>
                <p>It’s a vibe. Don’t be a poor fool. You can sleep when you die.</p>
                <div className="middle-text">
                    <p className="para">Be the first to know when the dopest <span>NFTs</span> drop. Sign up now.</p>
                    <Button click={props.onSignUp} />
                </div>
            </div>
            <div className="col col-img">
                <img className="swing-2" src={img4} alt="img4"/>
                <img className="swing swing-float" src={img5} alt="img5"/>
                <img className="swing-2-reverse" src={img6} alt="img6"/>
            </div>
            <div className="last-section">
                <p className="para">Be the first to know when the dopest <span>NFTs</span> drop. Sign up now.</p>
                <Button click={props.onSignUp} />
                <div className="footer-copy">
                    <span className="flex items-center flex-text-center copy-right">Powered by <img src={virtua} alt="virtua"/></span>
                </div>
            </div>
        </div>
    );
}