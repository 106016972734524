import {Home} from "./pages/home/Home";


function App() {
  return (
      <div id="wrapper">
          <Home />
      </div>
  );
}

export default App;
