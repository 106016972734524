import {Header} from "../../components/core/header/Header";
import {Banner} from "./banner/Banner";
import {Modal} from "../../components/modal/Modal";
import {useState} from "react";
import {Footer} from "../../components/core/footer/Footer";

export const Home = () => {
    const [active, setActive] = useState(false);

    return (
        <>
        <div className="container">
            <Header/>
            <main id="main">
                <Banner onSignUp={() => {
                    setActive(true);
                }} />
                <Modal active={active} onClose={() => {
                    setActive(false)
                }} />
            </main>
        </div>
        <Footer/>
        </>
    );
}