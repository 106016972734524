import logo from "../../../assets/images/logo.png";
import twitter from "../../../assets/images/twitter.svg";
import discord from "../../../assets/images/discord.svg";
import insta from "../../../assets/images/insta.svg";
import "./header.css";
export const Header = () => {
    return (
        <header id="header">
            <div className="flex items-center">
                <a className="logo" href="/">
                    <img src={logo} alt="logo"/>
                </a>
                <ul className="list-none flex items-center ml-auto socials">
                    <li>
                        <a href="https://twitter.com/Virtuametaverse" target="_blank">
                            <img src={twitter} alt="twitter" />
                        </a>
                    </li>
                    <li>
                        <a href="https://discord.com/invite/virtua" target="_blank">
                            <img src={discord} alt="discord" />
                        </a>
                    </li>
                    <li>
                        <a href="https://www.instagram.com/Virtuametaverse/" target="_blank">
                            <img src={insta} alt="insta" />
                        </a>
                    </li>
                </ul>
            </div>
        </header>
    );
}