import "./modal.css";
import close from "../../assets/images/close.png";
import discord from "../../assets/images/discord.png";
import { useState } from "react";
import { signUpRequest } from "../../services/api";
export const Modal = (props) => {

  let className = "popup flex items-center flex-text-center";
  if (props.active) className += " active";

  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const validateEmail = (value) => {
    let regEmail = /\S+@\S+\.\S+/;
    return regEmail.test(value);
  };

  const signup = async (email) => {
    setError("");
    if(!email) return setError("Please enter your email address");
    if (!validateEmail(email)) return setError("Enter the email address in valid format.");
    try {
      await signUpRequest(email);
      setSuccess(true);
    } catch (e) {
      setError(e.message);
    }
  };

  const resetStates = () => {
    setError('');
    setSuccess('');
    setEmail('')
    props.onClose();
  }

  return (
    <div className={className}>
      <div className="popup-holder relative">

        {success ? (
          <>
            <div className="congratulation">
              <h2>Congratulations</h2>
              <p>You have been Whitelisted Successfully</p>
              <a href="https://discord.com/invite/virtua" target="_blank" className="discord-text">
                <img src={discord} alt="discord" />{" "}
                <span>Join Virtua Discord</span>
              </a>
            </div>
          </>
        ) : (
         <>
         <h2>
           Be the first to know when the dopest <span>NFTs</span> drop{" "}
        </h2>
        <img
              className="close"
              src={close}
              alt="close"
              onClick={props.onClose}
            />
        <div className="email-section">
          <label htmlFor="email">Email Address</label>
          <span className="error">{error ?? ""}</span>
          <form className="flex items-center">
            <div className="col">
              <input
                id="email"
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                className="form-control"
                placeholder="Your Email Address"
              />
            </div>
            <div className="col">
              <a
                className="sign-up-btn"
                href="javascript:void(0)"
                onClick={() => {
                  signup(email);
                }}
              >
                Sign Up
              </a>
            </div>
          </form>
        </div>
         </>
        )}
        <img
              className="close"
              src={close}
              alt="close"
              onClick={resetStates}
            />
      </div>

    </div>
  );
};
