import './footer.css';
import virtua from '../../../assets/images/virtua.svg'

export const Footer = () => {

    return (
        <div className="copy-right">
        <p className="flex items-center flex-text-center">Powered by <img src={virtua} alt="virtua"/></p>
        </div>
    );
}