import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const signUpRequest = async (email) => {
  return await axios.post(`${BASE_URL}/api/users/createEmail`, {email}).catch(error => {
    if (error.response) {
        if(error.response.status === 400)
            throw Error("You have already signed up using this email address.");
      } 
      
      throw Error("Error signing up");

  })
}